import {CrudServices} from "@/services/core/crud.service";
import httpService from "@/services/core/http/http.service";
import {ODataUtils, Query, QueryResponse} from "@/services/core/odata/odata.services";
import {IFeedbackSetting} from "@/interfaces/dtos/feedback/IFeedbackSetting";
import {FeedbackInputType} from "@/interfaces/dtos/feedback/IFeedbackInputType";
import {IFeedbackSettingInput} from "@/interfaces/dtos/feedback/IFeedbackSettingInput";
import {IHttpResponse} from "@/interfaces/response/IHttpResponse";
import {API_KEY} from "@/utils/constants";
import Axios from "axios";
import {ConfirmationInputType} from "@/interfaces/dtos/confirmation/ConfirmationInputType";

export class ConfirmationServices  {
	async reconfirmEvent(eventId: string): Promise<IHttpResponse<any>> {
		const response = await httpService.get<any>(`visit/reconfirmation/${eventId}`);
		let result: any | undefined = undefined;
		if (response.success && response.result) {
			result = response.result;
		}
		return {
			success: response.success,
			message: response.message ?? undefined,
			result,
		};
	}
	async confirmEvent(query: ConfirmationInputType): Promise<IHttpResponse<any>> {
		const response = await httpService.post<ConfirmationInputType, any>(`visit/confirmation`, query);
		let result: any | undefined = undefined;
		if (response.success && response.result) {
			result = response.result;
		}
		return {
			success: response.success,
			message: response.message ?? undefined,
			result,
		};
	}
}

export default new ConfirmationServices();