










































import Vue from "vue";
import {Component} from "vue-property-decorator";
import { EVENT_STATUS } from "@/utils/constants";
import confirmServices from "@/services/services/confirmation.services";

@Component({
  components: { }
})
export default class Confirmation extends Vue {
  loading = true;
  validated = 1;
  status = EVENT_STATUS.PENDING;
  eventId = '';
  async created() {
    this.eventId = this.$route.params['eventId'];
    await this.syncReload();
  }

  async syncReload() {
    this.loading = true;
    const res = await confirmServices.reconfirmEvent(this.eventId);
    if (res.success && res.result) {
      this.validated = res.result.status === 1  ? res.result.status : 0;
    }
    this.loading = false;
  }

  async submitForm(action: number) {
    this.loading = true;
    this.validated = 1;
    if (action === 1) {
      this.status = EVENT_STATUS.CONFIRMED;
    }
    if (action === 2) {
      this.status = EVENT_STATUS.CANCELED;
    }
    const res = await confirmServices.confirmEvent({id: this.eventId, status: this.status});
    if (res.success) {
      if (action === 1) {
        this.$notify({
          type: "success",
          title: "Success",
          text: 'Thank you for filling out the appointment'
        });
      } else {
        this.$notify({
          type: "success",
          title: "Success",
          text: 'We are sorry for your cancellation, for a new appointment call 305.482.3216'
        });
      }
    } else {
      this.$notify({
        type: "error",
        title: "Error",
        text: 'An error occurred, please try again'
      });
    }
    this.loading = false;
  }
}
